import '../scripts/integrations/jquery-extended';
import '../scripts/integrations/turbo';
import '../scripts/integrations/legacy-namespaces/transitions';

import '../scripts/lib-legacy/cookies';
import '../scripts/lib-legacy/utils';
import '../scripts/lib-legacy/browserDetector';
import '../scripts/lib-legacy/mobile/base';
import '../scripts/lib-legacy/Ajaxify';
import '../scripts/lib-legacy/IdpRedirecter';
import '../scripts/lib-legacy/ui/formLoading';
import '../scripts/lib-legacy/policyPopup';
import '../scripts/lib-legacy/integrations';
import '../scripts/lib-legacy/oldBrowserMessage';

import loadApp from '../scripts/init';

const pages = import.meta.glob([
  '../scripts/pages/user_sessions/new.js',
], { eager: true });

document.addEventListener("turbo:load", function() {
  loadApp(pages);
});

// marketing now has async javascript (in production env only)
// Turbolinks load event doesn't fire with deferred scripts
// so we also need to add standard window load event.
if ($('html.marketing').length) {
  document.addEventListener("load", function() {
    loadApp(pages);
  });
}
